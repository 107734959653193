@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

  .gridwrapper {
    @apply grid grid-cols-12 gap-x-5 gap-y-2;
  }
  .gridmain {
    @apply col-start-2 col-end-12;
  }

  /* owl my three favorite lines of css */
  .flow > * + * {
    margin-block-start: 0.5em;
  }
  


/* ----- Schriften ----- */



/*---- Typo -----*/

body {
  @apply font-sans;
  hyphens: auto;
}

h1, h2, h3, h4, strong {
  @apply font-sans;
}

.prose-lg li::marker {
  /* unicode arrow right */
  content: "\2192\0020";
  font-size: 1.15em;
  @apply text-sky-600;
}

.blue-head strong {
  @apply text-sky-800;
  @apply font-semibold;
}

.caps {
  font-variant-caps: normal;
  text-transform: uppercase;
  @apply tracking-wider;
}

mark {
  @apply px-2;
  @apply py-1;
  @apply bg-yellow-700;
}



/*@layer base {

  .textblock p {
    @apply ft-text-base;
  }

  .textblock h2 {
    @apply ft-text-lg;
  }

  .textblock h3 {
    @apply ft-text-base;
  }

  figcaption {
    @apply ft-text-sm;
  }

}*/



.link {
  background: 
      linear-gradient(#dfeeee 0 0) 
      0 100% /var(--d, 0) 2px 
    no-repeat;
  transition:0.3s;
}
.link:hover {
  --d: 100%;
}

li > a, p > a {
  @apply text-sky-700;
  @apply underline;
  @apply underline-offset-4;
  @apply transition-all;
  @apply decoration-sky-200;
  @apply ease-in-out;
}

nav li>a {
  @apply text-sky-700;
  @apply no-underline;
  @apply transition-all;
  @apply decoration-sky-200;
  @apply ease-in-out;
}



li > a:hover, p > a:hover {
  @apply text-sky-700;
/*  @apply underline;
  @apply underline-offset-4;*/
  @apply transition-all;
  @apply decoration-sky-500;
  @apply ease-in-out;
}




/*
::::::::: Images ::::::::
*/



.gallery picture:nth-child(1) {
  grid-column: span 7 / span 7;
}

.gallery picture:nth-child(2) {
  grid-column: span 4 / span 4;
  place-self: end;
  padding-bottom: 2.5rem;
}

.gallery picture:nth-child(3) {
  grid-column-start: 4;
  grid-column-end: 8;
}

.gallery picture:nth-child(4) {
  grid-column-start: 8;
  grid-column-end: 13;
  margin-top: -2.5rem;
}

/* --- Lazysizes --- */

figure { width: 100%;
}

img[data-sizes="auto"] { display: block; width: 100%;
}

/* class name must matches the value of `ratio` setting. */

.lazysrcset-ratio {
  position: relative;
}

.lazysrcset-ratio > img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lazysrcset-ratio > img:after {
  display: block;
  width: 100%;
  height: 0;
  content: '';
}


.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 42.857143%; /* 21:9 Aspect Ratio (divide 9 by 21 = 0.42857143) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* Link zu openstreetmap */
.iframe-container small {
  z-index: 9000000000000000000;
  position: absolute;
  bottom: 1;
  background: white;
  padding: 0.25em 0.75em ;
  left: 1;
  @apply text-xs;
}
.iframe-container small a {
  @apply text-sky-600;
  @apply decoration-sky-200;
}








/* -----------------------------
fade in Animation 
------------------------------*/

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.fade-in {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}






/* -----------------------------
Form
------------------------------*/
form label {
  color:  white;
}

#nachricht {
  @apply py-1;
  @apply px-2;
}

.form-block-success p, form label {
  color:  white;
}









    /* verhindert, dass das KLarten Copyright über das menü läuft */
    .leaflet-bottom {
      z-index: 800 !important;
    }


    .stroke {
      text-shadow: 0px 0px 1px rgba(33, 94, 130, 0.7);
      @apply text-blue-80;
    }



/* -----------------------------
Scroll Margin
-------------------------------
Fügt kedem ID-container Scroll Margin zu,
damit er beim springen mit Ankern 
nicht hinter dem Menü verschwindet
------------------------------*/
[id] {
  scroll-margin-top: 8rem;
}


/* ----------------------------- 
  SVG
------------------------------*/

.svg-blue svg {
  @apply stroke-blue-500;
}




/* Cookie Banner
----------------------------------------------- */
.cookie-modal {
  font-size: 14px;
  z-index: 999999;
  /* width: 100%;
  height: 100%; */
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none
}

.cookie-modal--hidden {
  display: none
}

.cookie-modal__content {
  z-index: 999999;
  width: 100%;
  position: fixed;
  margin: 0 auto;
  bottom: 0px;
  padding: 1em;
  box-shadow: 0 10px 30px rgba(0, 0, 0, .2);
  background-color: #fff;
  pointer-events: auto
}

@media (min-width:400px) {
  .cookie-modal__content {
    padding: 2em;
  }
}

.cookie-modal__title {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 1em;
}

.cookie-modal__text {
  margin-bottom: 1.75em;
  line-height: 1.4
}

.cookie-modal__text a {
  text-decoration: underline
}

.cookie-modal__options {
  margin-bottom: 1.75em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap
}

.cookie-modal__options>* {
  width: calc(50% - 30px)
}

@media (min-width:400px) {
  .cookie-modal__options>* {
    width: calc(33.3333% - 30px)
  }
}

.cookie-modal__option {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 10px
}

.cookie-modal__option.disabled {
  opacity: .7
}

.cookie-modal__checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 0;
  border: 2px solid #0384c7;
  margin-right: 6px;
  flex-shrink: 0
}

.cookie-modal__checkbox:checked {
  background-color: #0384c7
}

.cookie-modal__checkbox:checked:focus {
  outline: none;
  background-color: #0384c7
}

.cookie-modal__checkbox:focus {
  outline: none;
  border-color: #0384c7
}

.cookie-modal__check {
  position: absolute;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0
}

.cookie-modal__check svg {
  stroke: #fff
}

.cookie-modal__label {
  line-height: 22px
}

.cookie-modal__buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start
}

.cookie-modal__button {
  display: block;
  margin-right: 8px;
  padding: 0.5em 1em;
  white-space: nowrap;
  border: 2px solid #0384c7;
  text-decoration: none;
  color: #0384c7
}

@media (min-width:400px) {
  .cookie-modal__button {
    margin-right: 10px;
    padding: 0.5em 1em;
  }
}

.cookie-modal__button.primary {
  background-color: #0384c7;
  color: #fff
}

.cookie-modal__button.primary:focus,
.cookie-modal__button.primary:hover {
  background-color: #fff;
  color: #0384c7;
}

.cookie-modal__button:focus,
.cookie-modal__button:hover {
  outline: none;
  border-color: #0384c7
}

.cookie-modal__button.hide {
  display: none
}

